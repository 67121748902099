import React from 'react'
import styled from 'styled-components';
import ThethreeD from '../components/Home/ThethreeD';
import threeDdata from '../Json/threeDdata.json';
import Layout from "../components/layout"
import Seo from "../components/seo"

const SectionPhotographyGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default function ThreeDpage() {
  return (
    <Layout>
      
    <Seo title="3D Modeling and Animation" />
    <div className="Hero">
      <div className="HeroGroup">
      <h2>3D Modeling and Animation</h2>
        <SectionPhotographyGroup>
          {threeDdata.threeDs.map((thethreeD) => (
            <ThethreeD
              title={thethreeD.title}
              title2={thethreeD.title2}
              title3={thethreeD.title3}
              title4={thethreeD.title4}
              image={thethreeD.image}
              image2={thethreeD.image2}
              image3={thethreeD.image3}
              image4={thethreeD.image4}
              image5={thethreeD.image5}
              text={thethreeD.text}
              text2={thethreeD.text2}
              text3={thethreeD.text3}
              text4={thethreeD.text4}
              url={thethreeD.url}
            />
          ))}
        </SectionPhotographyGroup>
      </div>
    </div>
    </Layout>
  );
}

