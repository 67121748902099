import styled from 'styled-components';
import React from 'react'

const StackGroup = styled.div`
  width: 100%;
  height: Auto;
  overflow: hidden;
  padding-bottom: 20px;
`;

const StackGrouptwo = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    grid-gap: 1em;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    grid-gap: 1em;
  }
`;

const StackMain = styled.div`
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  background-repeat: repeat-x;
  border-radius: 18px;
  height: 600px;

  @media (max-width: 600px) {
    height: 400px;
  }
`;

const StackMini = styled.div`
  width: 100%;
  border-radius: 18px;
  height: auto;
  background: #f5f5f7;

  &:hover {
    background: #ffe701;
  }

  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const StackMainTwo = styled.div`
  width: 100%;
  background-image: url(${(props) => props.image2});
  background-size: cover;
  background-position: center center;
  background-repeat: repeat-x;
  border-radius: 18px;
  height: 600px;

  @media (max-width: 600px) {
    height: 600px;
  }
`;

const StackMainThree = styled.div`
  width: 100%;
  background-image: url(${(props) => props.image2});
  background-size: cover;
  background-position: center center;
  background-repeat: repeat-x;
  border-radius: 18px 18px 0px 0px;

  height: 300px;

  @media (max-width: 600px) {
    height: 300px;
  }
`;

const StackTitle = styled.div`
  color: black;
  font-weight: 600;
  font-size: 24px;
  padding: 50px 20px 30px 20px;
`;

const StackTitle2 = styled.div`
  color: black;
  font-weight: 600;
  font-size: 24px;
  padding: 30px 30px 20px 30px;
`;

const StackText = styled.div`
  color: black;
  font-weight: 300;
  font-size: 16px;
  align-self: end;
  margin: 0 0 20px 20px;
  line-height: 1.8;
`;

const StackText2 = styled.div`
  color: black;
  font-weight: 300;
  font-size: 16px;
  align-self: end;
  padding: 0px 30px 60px 30px;
  line-height: 1.8;
`;

const Bonk = styled.div`
  text-align: right;
  width: 100%;
  position: absolute;
  bottom: 0;

  a {
    font-size: 12px;
    padding: 10px;
    margin: 10px;

    background: black;
    border-radius: 18px;
  }

  a {
    color: white;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  a:after {
    content: '»';
    position: absolute;
    opacity: 0;
    right: 0px;
    transition: 0.5s;
  }

  a:hover {
    padding-right: 24px;
    color: black;
    background: #ffe701;
  }

  a:hover:after {
    opacity: 1;
    right: 10px;
  }
`;

const StackAll = styled.div`
  width: 100%;
  min-height: auto;
  position: relative;
  border-radius: 18px;
`;

const ThethreeD = (props) => (
  <StackAll>
    <StackGroup>
      <StackMainTwo image2={props.image2} />
    </StackGroup>

    <StackGroup>
      <StackGrouptwo>
        <StackMini>
          <StackMainThree image2={props.image3} />
          <StackTitle2>{props.title2}</StackTitle2>
          <StackText2>{props.text2}</StackText2>
        </StackMini>

        <StackMini>
          <StackMainThree image2={props.image4} />
          <StackTitle2>{props.title3}</StackTitle2>
          <StackText2>{props.text3}</StackText2>
        </StackMini>

        <StackMini>
          <StackMainThree image2={props.image5} />
          <StackTitle2>{props.title4}</StackTitle2>
          <StackText2>{props.text4}</StackText2>
        </StackMini>
      </StackGrouptwo>
    </StackGroup>

    <StackGroup>
      <StackMain image={props.image} />
      <StackTitle>{props.title}</StackTitle>
      <StackText>{props.text}</StackText>
      <a href={props.url}> </a>
    </StackGroup>
  </StackAll>
);

export default ThethreeD;
